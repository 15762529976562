import '@github/g-emoji-element'

import type {InlineAutocompleteProps} from '@github-ui/inline-autocomplete'
import type {ShowSuggestionsEvent, Suggestions} from '@github-ui/inline-autocomplete/types'
import {useEmojiSuggestions} from '@github-ui/markdown-editor/suggestions/use-emoji-suggestions'
import {useMemo, useState} from 'react'

import {emojiCharMap} from '../../helpers/emojis'
import {getInitialState} from '../../helpers/initial-state'

const emojiSuggestions = Object.entries(emojiCharMap).map(([name, character]) => ({name, character}))

export const useEmojiAutocomplete = (): Omit<InlineAutocompleteProps, 'children'> => {
  const {themePreferences} = getInitialState()
  const emojiConfig = useMemo(
    () => ({
      tone: themePreferences?.preferred_emoji_skin_tone,
    }),
    [themePreferences?.preferred_emoji_skin_tone],
  )

  const {calculateSuggestions, trigger} = useEmojiSuggestions(emojiSuggestions ?? [], emojiConfig)

  const triggers = useMemo(() => [trigger], [trigger])

  const [suggestions, setSuggestions] = useState<Suggestions | null>(null)

  const onShowSuggestions = async ({query}: ShowSuggestionsEvent) => {
    // no network request is made so this loading spinner never be visible, but it's likely
    // we'll use an API for this in the future so this is more future proof
    setSuggestions('loading')

    setSuggestions(await calculateSuggestions(query))
  }

  return {
    triggers,
    suggestions,
    onShowSuggestions,
    onHideSuggestions: () => setSuggestions(null),
  }
}
